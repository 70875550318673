import "./styles/style.scss";

addEventListener("DOMContentLoaded", (event) => {
  console.log("wiring page elements");

  // Theme
  document.getElementById("reset-theme").addEventListener("click", () => {
    window.localStorage.theme = undefined;
    window.setTheme();
  });
  document.getElementById("light-theme").addEventListener("click", () => {
    window.localStorage.theme = "light";
    window.setTheme();
  });
  document.getElementById("dark-theme").addEventListener("click", () => {
    window.localStorage.theme = "dark";
    window.setTheme();
  });

  // Mailing list
  const mailingListModal = document.getElementById("mailing-list-modal");
  const showSubscribe = document.getElementById("show-subscribe");
  const hideSubscribe = document.getElementById("hide-subscribe");

  showSubscribe.addEventListener("click", () => {
    mailingListModal.classList.remove("hide");
    showSubscribe.classList.add("hide");
    hideSubscribe.classList.remove("hide");
  });
  hideSubscribe.addEventListener("click", () => {
    mailingListModal.classList.add("hide");
    showSubscribe.classList.remove("hide");
    hideSubscribe.classList.add("hide");
  });
});
